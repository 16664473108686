import React from 'react';
import { getSrc } from "gatsby-plugin-image";
import { graphql } from 'gatsby';

import Footer from '../blog/components/Footer';
import IndexLayout from '../layouts';
import PostCard from '../blog/components/PostCard';
import SiteNav from '../blog/components/Header/SiteNav';
import Wrapper from '../blog/components/Wrapper/Wrapper';

import MetaData from '../containers/MetaData';

import {
  inner,
  outer,
  PostFeed,
  SiteDescription,
  SiteHeader,
  SiteHeaderContent,
  SiteMain,
  SiteTitle,
} from '../styles/shared';

const Tags = props => {
  const tag = (props.pageContext.tag) ? props.pageContext.tag : '';
  const { edges, totalCount } = props.data.allMarkdownRemark;
  const tagData = props.data.allTagYaml.edges.find(
    n => n.node.name.toLowerCase() === tag.toLowerCase(),
  );

  return (
    <IndexLayout>
    <MetaData 
      description={tagData?.node ? tagData.node.description : `Everything you need to know about ${tag}`}
      height={tagData?.node.image ? tagData.node.image.childImageSharp.gatsbyImageData.width : null}
      image={tagData?.node.image ? getSrc(tagData.node.image.childImageSharp) : null}
      imageAlt={tagData?.node ? tagData.node.imageAlt : null}
      robots="index, follow"
      title={`${tag} | David Nowak`}
      url={props.pageContext.slug}
      width={tagData?.node.image ? tagData.node.image.childImageSharp.gatsbyImageData.height : null}
    />
      <Wrapper>
        <SiteHeader
          className={`${tagData?.node.image ? '' : 'no-cover'}`}
          css={outer}
          bgImg={tagData?.node?.image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
        >
          <div css={inner}>
            <SiteNav isHome={false} />
            <SiteHeaderContent>
              <SiteTitle>{tag}</SiteTitle>
              <SiteDescription>
                {tagData && tagData.node.description ? (
                  tagData.node.description
                ) : (
                  <>
                    A collection of {totalCount > 1 && `${totalCount} posts`}
                    {totalCount === 1 && '1 post'}
                    {totalCount === 0 && 'No posts'}
                  </>
                )}
              </SiteDescription>
            </SiteHeaderContent>
          </div>
        </SiteHeader>
        <SiteMain useOuter>
          <div css={inner}>
            <PostFeed>
              {edges.map(({ node }) => (
                <PostCard key={node.fields.slug} post={node} />
              ))}
            </PostFeed>
          </div>
        </SiteMain>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default Tags;

export const pageQuery = graphql`
  query($tag: String) {
    allTagYaml {
      edges {
        node {
          name
          description
          imageAlt
          image {
            childImageSharp {
              gatsbyImageData(width: 3720) 
            }
          }
        }
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, draft: { ne: true } } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            date
            userDate: date(formatString: "MMMM DD, YYYY")
            image {
              childImageSharp {
                gatsbyImageData(width: 1240)
              }
            }
            author {
              name
              bio
              avatar {
                children {
                  ... on ImageSharp {
                    gatsbyImageData(quality: 90)
                  }
                }
              }
            }
          }
          fields {
            layout
            slug
          }
        }
      }
    }
  }
`;
